import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_TICKETS);

export default {
  listar_tickets() {
    return http.get(`/micuenta/tickets?order_col=id&order_dir=desc`);
  },

  obtener_ticket(id) {
    return http.get(`/micuenta/tickets/${id}`);
  },

  crear_ticket(payload) {
    return http.post(`/micuenta/tickets`, payload);
  },

  crear_comentario(ticket, payload) {
    return http.post(`/micuenta/tickets/${ticket}/comentarios`, payload);
  },

  cargar_documento(ticket, payload) {
    return http.post(`/micuenta/tickets/${ticket}/documentos`, payload);
  },

  descargar_documento(ticket, documento) {
    return http.get(`/micuenta/tickets/${ticket}/documentos/${documento}`,{responseType: 'blob'})
  },

  listar_areas() {
    return http.get(`/micuenta/areas?f[0][column]=estatus&f[0][operator]=equal_to&f[0][query_1]=1&relations[0]=requerimientos.campos`);
  }

}